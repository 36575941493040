import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
// 引入移动端自适应
// import 'lib-flexible';

//引入rootReducer组件
import { Provider } from "react-redux";
import store from "./store";
import { HashRouter } from "react-router-dom";
import { PolestarApp } from "@polestar/component-warehouse-react";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        {/* provider组件将所有的组件包裹起来，用绑定属性的形式绑定store到组件中 */}
        <Provider store={store}>
            <PolestarApp>
                <HashRouter>
                    <App/>
                </HashRouter>
            </PolestarApp>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
