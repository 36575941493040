import "./index.less"
import {
    Column, Footer,
    Heading, MainAxisAlignments, MediaQueries, MediaSection, Row,
    Section, SectionSpacer, Sizes, Tabs, Image, useMediaQuery,
} from "@polestar/component-warehouse-react";
import styled from '@emotion/styled';
import { useEffect, useState } from "react";
import SearchField from '../../components/eip/SearchField';
import legalLinksData from './data/legalLinksData.json';
import { getEipAllData, getResourceData } from "../../service/eip";
import ControlledAccordion from "../../components/eip/ControlledAccordion";
import { EipInterface } from "../../interface/eip";

export default function Eip() {
    useEffect(() => {
        initData();
    }, []);

    const [connectData, setConnectData] = useState<EipInterface.Category[][] | null>(null);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [tabs, setTabs] = useState<EipInterface.Tab[] | null>(null);

    async function initData() {
        document.title = getResourceData().documentTitle;

        const res = await getEipAllData();
        setTabs(res.tabs);

        const connectData = res.tabs.map((item) => {
            const data = res.data[item.code][0];
            return data.categories
        })
        setConnectData(connectData);
    }

    /** 设备兼容 */
    let queryMatchesValue = "";
    const { queryMatches } = useMediaQuery();
    if (queryMatches.mobile) queryMatchesValue = "mobile";
    if (queryMatches.desktopWide) queryMatchesValue = "desktopWide";

    return (
        <>
            {/* eip */}
            <div className="eip">

                {/* head */}
                <MediaSection>
                    <Image
                        src={getResourceData().bannerSrc}
                        alt="Polestar 极星"
                        objectFit="contain"
                        objectPosition="50% 50%"
                        stretch
                    />
                </MediaSection>
                <SectionSpacer size={Sizes.small}/>
                <Section>
                    <Heading level={1}>车型环保信息公开查询</Heading>
                </Section>
                <SectionSpacer size={Sizes.small}/>

                {/* tab + search */}
                <Section>
                    <StyledRow mainAxisAlignment={MainAxisAlignments.spaceBetween}>
                        {queryMatchesValue === "mobile" &&
                            <SearchColumn>
                                <SearchField/>
                                <SectionSpacer size={Sizes.small}/>
                            </SearchColumn>
                        }
                        <TabColumn>
                            {tabs && <Tabs
                                tabs={tabs}
                                onTabClick={(index) => {
                                    setActiveTab(index)
                                }}
                                activeTab={activeTab}
                            />}
                        </TabColumn>
                        {queryMatchesValue !== "mobile" &&
                            <SearchColumn>
                                <SearchField/>
                            </SearchColumn>
                        }
                    </StyledRow>
                </Section>
                <SectionSpacer size={Sizes.small}/>

                {/* tab */}
                <Section>
                    {
                        tabs && tabs.map((item, index: number) => (
                            activeTab === index && connectData &&
                            <ControlledAccordion key={item.label} data={connectData[index]}/>
                        ))
                    }
                </Section>
                <SectionSpacer/>
            </div>

            {/* foot */}
            <Footer
                copyrightText={getResourceData().footerCopyrightText}
                legalLinks={legalLinksData}
            />
        </>
    )
}

const StyledRow = styled(Row)`
  flex-direction: column;

  ${MediaQueries.tablet} {
    flex-direction: row;
  }
`;

const TabColumn = styled(Column)`
  width: 100%;
  justify-content: flex-end;

  ${MediaQueries.tablet} {
    width: 60%;
  }
`;

const SearchColumn = styled(Column)`
  width: 50%;

  ${MediaQueries.mobile} {
    width: 100%;
  }

  ${MediaQueries.tablet} {
    width: 40%;
  }
`;

export interface SupportDataCategory {
    title: string;
    items: SupportDataCategoryItem[];
}

export interface SupportDataCategoryItem {
    title: string;
    url: string;
}
