export function getEipDomain(type: "eip" | "api") {
    const h = window.location.href;
    let href;
    if (h.indexOf("dev") > -1) {
        href = `https://${type}.pacd-dev.origin.polestar.cn`;
    } else if (h.indexOf("staging") > -1) {
        href = `https://${type}.pacd-staging.origin.polestar.cn`;
    } else if (h.indexOf("pacd-prod") > -1) {
        href = `https://${type}.pacd-prod.origin.polestar.cn`;
    } else if (h.indexOf("eip.polestar.cn") > -1) {
        href = `https://${type}.pacd-prod.origin.polestar.cn`;
    } else {
        href = `https://${type}.pacd-dev.origin.polestar.cn`;
    }
    return href;
}

export function getEipWebDomain() {
    return getEipDomain("eip");
}

export function getEipApiDomain() {
    return getEipDomain("api");
}
