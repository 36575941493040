import React from "react";
import "./App.css";
// 引入routes组件
import { useRoutes } from "react-router-dom";
// 引入connect连接组件
// import { connect } from "react-redux"
import routes from './routes'

function App() {
    const element = useRoutes(routes);
    return (
        <>
            {element}
        </>
    );
}

//进行连接
// export default connect(
//     (props, state) => Object.assign({}, props, state),
//     {}
// )(App);

export default App;
