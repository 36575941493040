import { service } from "./request";
import { EipInterface } from "../interface/eip";
import { getEipApiDomain } from "./env";

//获取 model type
export function getEipModelTypeApi(): Promise<EipInterface.Response> {
    return service.get(`${getEipApiDomain()}/api/eip/model_type`);
}

// 获取 vin 详情
export function getDetailByVinApi(vin: string): Promise<EipInterface.DetailByDetail> {
    return service.get(`${getEipApiDomain()}/api/eip/vin/${vin}`)
}

