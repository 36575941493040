import {
    Colors,
    Dialog,
    Heading,
    IconButton, IconButtonVariants,
    Icons,
    Link,
    Paragraph,
    Sizes,
    Spacer,
    TextButton,
    TextField
} from '@polestar/component-warehouse-react';
import React, { FC, Fragment, useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { getDetailInfoByVin } from "../../service/eip";

const SearchField: FC = () => {
    /** 弹框 */
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState({ title: "", url: "" });
    const handleDialogClick = useCallback(() => {
        setDialogOpen(true);
    }, []);
    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, []);


    /** 输入框 */
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [value, setValue] = useState("");
    const setInputValue = (value: string) => {
        setErrorMessage(null);
        setValue(value);
    }
    const onKeyDown = useCallback(
        async (event: { key: string; }) => {
            if (event.key === "Enter") {
                if(!value) return;
                const res = await getDetailInfoByVin(value);
                // console.log(res);
                if (!res.rtn) {
                    setErrorMessage("当前VIN号不存在")
                } else {
                    handleDialogClick();
                    setSearchValue({
                        title: res.data.file.fileName,
                        url: res.data.file.filePath
                    })
                }
            }
        },
        [value, handleDialogClick],
    );

    // 清除输入框内容
    const inputElement = useRef(null);
    const handleClear = useCallback(() => {
        const current = inputElement.current;
        setValue("");
        setErrorMessage(null);
        // @ts-ignore
        current && current.focus();
    }, []);


    return (
        <Fragment>
            <StyledAutocompleteField
                ref={inputElement}
                trailing={
                    <IconButton
                        aria-label="Clear"
                        onClick={handleClear}
                        disabled={false}
                        icon={Icons.close}
                        size={Sizes.medium}
                        variant={IconButtonVariants.transparent}
                        style={{
                            marginInlineEnd: "-.25rem",
                        }}
                    />
                }
                value={value}
                onChange={(value) => setInputValue(value)}
                label="EIP查询"
                error={false}
                disabled={false}
                required={false}
                fieldProps={{ role: "search" }}
                onKeyDown={onKeyDown}
                errorMessage={errorMessage}
                placeholder={"请输入完整的VIN号"}
            />

            <Dialog
                detached
                open={dialogOpen}
                onClose={handleDialogClose}
                withCloseButton={true}
                closeButtonProps={{ 'aria-label': 'close' }}
            >
                <Heading level={1}>搜索结果：</Heading>
                <Spacer/>
                <Paragraph>
                    VIN对应的环保信息链接 :{' '}
                    <Link href={searchValue.url} target="_blank" rel="noopener">
                        <TextButton style={{ color: Colors.accessibleSafetyOrange }}>
                            {searchValue.title}
                        </TextButton>
                    </Link>
                </Paragraph>
                <Spacer/>
                <Spacer/>
                <Spacer/>
                <Spacer/>
            </Dialog>

        </Fragment>
    );
};

const StyledAutocompleteField = styled(TextField)`
  min-width: 300px;
`;

export default SearchField;
