import {
  AccordionSection,
  Link,
  Spacer,
  Spacings,
  TextButton,
} from '@polestar/component-warehouse-react';
import React, { FC, useState } from 'react';
import { SupportDataCategory } from '../../pages/eip';

const ControlledAccordion: FC<{ data: SupportDataCategory[] }> = ({ data }) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  return (
    <div>
      {data.map((section, index) => {
        const id = `s${index}`;

        return (
          <AccordionSection
            id={id}
            key={index}
            title={section.title}
            onToggle={(id, expanded) => {
              setSelected(expanded ? id : undefined);
            }}
            expanded={id === selected}
          >
            {section.items.map((link, linkIndex) => (
              <div key={`s${index}l${linkIndex}`}>
                <Link href={link.url} target="_blank" rel="noopener">
                  <TextButton>{link.title}</TextButton>
                </Link>
                <Spacer spacing={Spacings.small} />
              </div>
            ))}
          </AccordionSection>
        );
      })}
    </div>
  );
};

export default ControlledAccordion;
