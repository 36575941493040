// 导入路由组件
import Eip from "../pages/eip";
import Demo from "../pages/demo";

const routes = [
    {
        path: '/eip',
        element: <Eip/>
    },
    {
        path: '/',
        element: <Eip/>
    },
    {
        path: '/demo',
        element: <Demo/>
    }
]

export default routes;

// export default [
//     // 路由表
//     {
//         path: '/',
//         element: <ChooseVersion />
//     },
//     {
//         path: 'configurator',
//         element: <Configurator />,
//         redirectTo:'/configurator/configColor',
//         children:[
//             {
//                 path:'configColor',
//                 element:<ConfigColor/>
//             },
//             {
//                 path:'configTrim',
//                 element:<ConfigTrim/>
//             },
//             {
//                 path:'configOption',
//                 element:<ConfigOption/>
//             }
//         ]
//     },
//     {
//         path: '/chooseVersion',
//         element: <ChooseVersion />
//     }
// ]
