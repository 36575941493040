import axios, { AxiosResponse } from "axios";
import { EipInterface } from "../interface/eip";

export const service = axios.create();
service.defaults.timeout = 50000;
let requestCount = 0;

function showLoading() {

    if (requestCount === 0) {
        let dom = document.createElement("div");
        dom.setAttribute("id", "loading");
        document.body.appendChild(dom);
    }
    requestCount++;
}

function hideLoading() {
    requestCount--;
    if (requestCount === 0) {
        // @ts-ignore
        document.body.removeChild(document.getElementById("loading"));
    }
}

// request 拦截器
service.interceptors.request.use(
    (config) => {
        showLoading();
        return Promise.resolve(config);
    },
    (err) => {
        return Promise.reject(err);
    }
);

// 响应拦截器
// @ts-ignore
service.interceptors.response.use((response: AxiosResponse) => {
        hideLoading();
        const { data, status } = response;
        const res = data as EipInterface.Response;
        if (status === 200 || status === 201) {
            if (res.rtn) return Promise.resolve(res);
            else return Promise.resolve(res);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        hideLoading();
        if (error?.response?.status === 400) {
            return Promise.resolve(error?.response?.data);
        }
        return Promise.reject(error);
    }
);
